const packageJson = require('../package.json');

module.exports = {
    VERSION: 'v' + packageJson.version || 'DEV',
    API_LOCATION: 'https://api.mygmrs.com',
    UI_LOCATION: 'https://mygmrs.com',
    // API_LOCATION: 'https://local.mygmrs.com/api',
    // UI_LOCATION: 'https://local.mygmrs.com',
    MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoibXlnbXJzIiwiYSI6ImNrYjgzODdtcTAwYXcycnMwdjFvZ292bmsifQ.SJP7jVz6UeY-cmtVkYlMTQ',
    STRIPE_PUBLISHABLE_KEY: 'pk_live_516Rk11JN69qfwFq6NJejpCu5Rrl8UfPKU1MKub48jpDZQTLa4PFFdJftzELmPDdpUPJI7Lxe625uyQ17DCm88o9q007QqWcJqn',
    SUBSCRIPTIONS_ENABLED: true,
    STRIPE_PRICE_MONTHLY: 'price_1Jg1tzJN69qfwFq6ZeRSRz0A',
    STRIPE_PRICE_YEARLY: 'price_1Jg1tzJN69qfwFq62SI3mZ7o'
};
